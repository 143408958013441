import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Home from "./screens/homepage/home";
import About from "./screens/about/About";
import "./App.css";
import PrivacyPolicy from "./screens/privacypolicy";
import ContactUs from "./screens/contactus";
import Newfranchise from "./screens/newfranchisepage";
import ReactGA from "react-ga";
import Navbar from "./screens/navbar";
import { Toaster } from "react-hot-toast";
import Gallery from "./screens/gallery";
import NewGallery from "./screens/gallery/NewGallery";
import "animate.css/animate.min.css";
import NewFooter from "./screens/footer";
import Locations from "./screens/locations/Locations";
const TRACKING_ID = "G-H8LMPD3V4F"; // Replace with your tracking ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");

  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 1000);
  }

  return (
    <div className="roboto-regular relative">
    <Toaster/>
    {
    !loading && (
      <BrowserRouter>
        {window.location.pathname !== "/customSalon" && <Navbar />}
        <ScrollToTop/>
        <div className="xl:mt-[105px]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
        
          <Route path="/salon-location-near-me" element={<Locations />} />


          <Route path="/franchise-enquiry" element={<Newfranchise />} />
          <Route path="/franchise-d" element={<Newfranchise />} />
          <Route path="/franchise-enquiry*" element={<Newfranchise />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/media" element={<NewGallery />} />
          <Route path="*" element={<Newfranchise/>}/>
        </Routes>
        </div>
        {window.location.pathname !== "/customSalon" && <NewFooter />}

        {/* */}
        {/* <FloatingButton/> */}
      </BrowserRouter>
    )}
    </div>
  );
}
