import { useEffect, useState } from "react";
import MainText from "../../components/mainTextcomponent";
import { getApiCall } from "../../utils/services";
import { Helmet } from "react-helmet";

const Locations = () => {
    const [salons,setSalons]=useState([])

  useEffect(() => {
    getApiCall(
      "salons",

      (res) => {
        setSalons(res);
      },
      (err) => {
      }
    );
  }, []);
  return (
    <>
    <Helmet>
        <title>All Monsoon Salon Location and Contact Number</title>
        <meta name="description" content="Monsoon Salon. Kankavali. V Mall, Valanju building, Bazarpet Rd, near Zenda Chouk, Kankavli, Maharashtra 416602. Open all days: 10am to 8pm · Monsoon Salon ..." />
        <meta name="keywords" content="Monsoon Salon near me, Salon near me, Monsoon Salon information, Monsoon Salon address, Monsoon Salon location" />
      
        
     
   
      </Helmet>
      <div className="">
      <div className="py-9">

     
        <MainText textdata={"Our Salons"} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 min-h-[60vh]  lg:grid-cols-3 xl:grid-cols-4 gap-5 w-[95%] md:[90%] lg:w-[85%] xl:w-[80%] mx-auto">
        {
            salons?.length>0&& salons?.map((salon,idx)=>{
                return (
                    <div key={idx} className="border rounded-xl p-3 shadow-xl ">
                    <div className="">
                    <h2 className="font-bold text-2xl mb-3">{salon.name}</h2>
                    <h2 className=" text-lg mb-3 line-clamp-4 overflow-hidden text-ellipsis h-[120px]">{salon.address}{" "}{salon.address2}</h2>
                    <h2 className=" text-lg font-medium  mb-3">{salon.stateName}</h2>
                      <div className="flex gap-3">
                        <button className="transition-all ease-in duration-100 bg-black border border-transparent hover:border-black hover:bg-white hover:text-black  w-1/2 h-[40px] flex items-center justify-center text-white font-bold text-md rounded-md ">Locate</button>
                        <button className="transition-all ease-in duration-100 border border-black  w-1/2 h-[40px] hover:bg-black hover:text-white flex items-center justify-center text-black font-bold text-md rounded-md">Visit</button>
                      </div>
                    </div>


                    </div>
                )
            })
        }

        </div>
      </div>
    </>
  );
};

export default Locations;
