import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./home.css";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { brandsImg, OurServicesData } from "../utils/dummydata";
import LOCAL_IMAGES from "../utils/localImages";
import Testimonial from "../../components/Testimonial";
import Header from "../../components/Header";
import ServiceCart from "../../components/ServiceCart";
import Banner from "../../components/banners/Banner";
import LatestWork from "../../components/latestWork/LatestWork";
// import "animate.css/animate.compat.css";
// const banner1 = require("../../../src/assets/images/banner1.jpeg");
const banner2 = require("../../../src/assets/images/banner2.jpg");
const banner3 = require("../../../src/assets/images/banner3.jpg");
const newsmdiaData = [
  {
    videourl: LOCAL_IMAGES.inst12Icon,
  },

  {
    videourl: LOCAL_IMAGES.inst14Icon,
  },
  {
    videourl: LOCAL_IMAGES.inst15Icon,
  },
  {
    videourl: LOCAL_IMAGES.inst16Icon,
  },

  {
    videourl: LOCAL_IMAGES.inst212Icon,
  },
];

export default function Home() {
  const navigate = useNavigate();
  const GTM_ID = "GTM-T37QZ7DB";
  const exploremore = () => {
    navigate("/video");
  };
  const newSettings = {
    dots: false,
  };
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    pauseOnHover: false,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsreview = {
    dots: true,
    // infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          //   dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      bgimge: LOCAL_IMAGES.quoteicon,
      para: "I brought my daughter to Monsoon Salon for her first haircut, and the experience was wonderful.The stylist was patient and gentle,and they made it a memorable and positive experience for her.",
      name: "Arpita Solanki",
      nameImg:
        "https://argatinkeratin.com/wp-content/uploads/2022/07/download_1.jpg",
    },
    {
      bgimge: LOCAL_IMAGES.quoteicon,
      para: "I've been to many salons in the past, but Monsoon Salon stands out for its professionalism and attention to detail. The quality of service and products they use is top-notch. I won't go anywhere else.",
      name: "Roshni panchal",
      nameImg:
        "https://argatinkeratin.com/wp-content/uploads/2022/07/istockphoto-578811140-612x612-1.jpg",
    },
    //  {
    //   bgimge:LOCAL_IMAGES.quoteicon,
    //   para:"I went to Monsoon Salon for a bridal hair and makeup trial, and I was blown away by the results. The team really listened to my vision and brought it to life. I can't wait for them to make me look stunning on my wedding day!",
    //   name:'Kanchan Pawar',
    //   nameImg:'https://argatinkeratin.com/wp-content/uploads/2022/07/istockphoto-578811140-612x612-1.jpg'

    //  },
    {
      bgimge: LOCAL_IMAGES.quoteicon,
      para: "I was nervous about trying a new salon, but Monsoon Salon exceeded all my expectations. The staff is knowledgeable about the latest trends and techniques, and they made me feel comfortable and valued as a customer.",
      name: "Priyanka Praksh",
      nameImg:
        "https://argatinkeratin.com/wp-content/uploads/2022/07/images.jpg",
    },

    {
      bgimge: LOCAL_IMAGES.quoteicon,
      para: "As someone who's always struggled with hair color, I was nervous about trying something new. But Monsoon Salon team of experts put me at ease. My hair has never looked so beautiful and vibrant!",
      name: "Priya Singh",
      nameImg:
        "https://argatinkeratin.com/wp-content/uploads/2022/07/download.jpg",
    },
  ];
  const onpresspaper = () => {
    navigate("/salondiscription");
  };
  const gradientStyle = {
    backgroundImage: "linear-gradient(to right, #bdc3c7, #2c3e50)", // Adjust gradient colors
    height: "380px",
    marginTop: 5,
    width: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "60px",
  };
  const handleInstagramNavigate = () => {
    window.location.href = "https://www.instagram.com/monsoonsalon/";
  };
  const bannerImages = [
    // banner1,
    banner3,
    LOCAL_IMAGES.banner4,
    LOCAL_IMAGES.banner6,
  ];
  return (
    <>
      <div className="roboto-regular ">
        <Helmet>
          <title>Monsoon Salon </title>
          <meta
            name="description"
            content="In our initial phase, Monsoon will be the market leader with smart placement across top 100 cities. We want to have over 100 salons within the next two years, ...
"
          />
          <meta
            name="keywords"
            content="salon franchise, salon chain in india, salon franchise portal, affordable salon chain in india"
          />
          {/* <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-T37QZ7DB');
      `}</script> */}

          {/* Google Tag Manager (noscript) */}
          {/* <noscript>
       {` <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T37QZ7DB"
          height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>`}
      </noscript> */}
        </Helmet>
        <div className="mainsliderContainer">
          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={true}
            infiniteLoop={true}
            autoPlay={true}
            interval={2000}
            pauseOnHover={false}
            showThumbs={false}
          >
            {bannerImages?.map((image, index) => (
              <div key={index} className="h-full ">
                <Banner image={image} />
              </div>
            ))}
          </Carousel>
        </div>

        {/* <HomapgeAbout/> */}
        <Header />

        <LatestWork />

        {/* <MainText textdata={"Our Services"}/> */}

        <h1 className="text-xl font-medium text-center  roboto-regular opacity-80 ">
          What We Do 
        </h1>
        <h1 className="text-[2.5rem] md:text-[3rem] px-3 md:px-5 text-center font-medium font-Cormorant opacity-80 mb-5">
          Your Complete Beauty Journey Starts{" "}
        </h1>

        <div className="px-3 sm:w-[90%] 2xl:w-[70%] mx-auto grid grid-cols-1 lg:grid-cols-2 place-items-center justify-items-center   md:mb-10">
          {OurServicesData.map((item, index) => (
            <ServiceCart
              index={index}
              img={item.serviceIcon}
              name={item.serviceName}
              desc={item.serviceDiscipton}
            />
          ))}
        </div>

    
        {/* <BookingSection/> */}
        <Testimonial />

        <h1 className="text-2xl md:text-4xl text-center font-normal roboto-regular opacity-80 mb-5">
          Partner Brands
        </h1>
        <div className="mb-16 grid gap-4  grid-cols-2 md:grid-cols-3 w-[90%] md:w-[80%] 2xl:w-[70%] mx-auto">
          {brandsImg?.map((item, index) => {
            return (
              <div
                key={index}
                className={` border flex items-center justify-center`}
              >
                <img
                  src={item.brandUrl}
                  style={{ height: "70%", width: "70%" }}
                  alt="galleryimages"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
