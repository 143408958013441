import TestimonalSlider from './TestimonialSider'

const Testimonial = () => {
  return (
    <div className='w-full  bg-white flex flex-col my-9 py-2'>
    <h1 className='text-xl md:text-3xl font-bold text-center text-black mt-3'>TESTIMONIALS SPOTLIGHT </h1>
    <TestimonalSlider />
   
    </div>
  )
}

export default Testimonial